var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-activity-container"},[_c('vs-row',{staticClass:"items-start"},[_c('vs-col',{attrs:{"vs-align":"center","vs-justify":"center","vs-type":"flex","vs-w":"12"}},[_c('vx-card',{staticClass:"custom-vx-card",staticStyle:{"background":"#f8f8f8"}},[_c('div',{staticClass:"flex mb-2"},[_c('vs-button',{staticClass:"ml-auto",on:{"click":_vm.exportCSV}},[_vm._v("Export")])],1),_c('vs-table',{staticClass:"pay-activity-table stripes",attrs:{"data":_vm.missedPayments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.paymentRequest}},[_vm._v(_vm._s(tr.paymentRequestId))]),_c('vs-td',{attrs:{"data":tr.paymentRequest}},[_vm._v(_vm._s(tr.selectedPaymentPlan.productName))]),_c('vs-td',{attrs:{"data":tr.paymentRequest}},[_vm._v(_vm._s(_vm.formatCurrency(tr.balances.overdueBalance)))]),_c('vs-td',{attrs:{"data":tr.paymentRequest}},[_vm._v(_vm._s(tr.balances ? _vm.formatCurrency(tr.balances.balance) : 'n/a'))]),_c('vs-td',{attrs:{"data":tr}},[_vm._v(_vm._s(tr.balances.overdueDays))]),_c('vs-td',{staticClass:"text-center",attrs:{"data":tr}},[_c('a',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){return _vm.viewDetail(tr)}}},[_vm._v("View")])])],1)})}}])},[_c('template',{slot:"header"}),_c('template',{slot:"thead"},[_c('vs-th',[_c('div',{staticClass:"boxBody"},[_c('filter-label-component',{attrs:{"searchParam":_vm.searchParam,"title":'Request ID',"fieldName":'paymentRequestId',"filtersHidden":_vm.filtersHidden},on:{"changeFilter":_vm.changeFilter}}),(_vm.searchParam.paymentRequestId)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'paymentRequestId')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.paymentRequestId,
                    textBlue: _vm.searchParam.paymentRequestId,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.paymentRequestId),callback:function ($$v) {_vm.$set(_vm.searchParam, "paymentRequestId", $$v)},expression:"searchParam.paymentRequestId"}}):_vm._e()],1)]),_c('vs-th',[_c('div',{staticClass:"boxBody"},[_c('filter-label-component',{attrs:{"searchParam":_vm.searchParam,"title":'Product',"fieldName":'product',"filtersHidden":_vm.filtersHidden},on:{"changeFilter":_vm.changeFilter}}),(!_vm.filtersHidden)?_c('vs-select',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.product,
                    textBlue: _vm.searchParam.product,
                  },attrs:{"multiple":true},model:{value:(_vm.searchParam.product),callback:function ($$v) {_vm.$set(_vm.searchParam, "product", $$v)},expression:"searchParam.product"}},_vm._l((_vm.products),function(item,index){return _c('vs-select-item',{key:index,attrs:{"text":item,"value":item},nativeOn:{"click":function($event){return _vm.checkProduct(item.productName)}}})}),1):_vm._e()],1)]),_c('vs-th',[_c('div',{staticClass:"boxBody"},[_c('filter-label-component',{attrs:{"searchParam":_vm.searchParam,"title":'Amount',"fieldName":'amount',"filtersHidden":_vm.filtersHidden},on:{"changeFilter":_vm.changeFilter}}),(_vm.searchParam.amount)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'amount')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.amount,
                    textBlue: _vm.searchParam.amount,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.amount),callback:function ($$v) {_vm.$set(_vm.searchParam, "amount", $$v)},expression:"searchParam.amount"}}):_vm._e()],1)]),_c('vs-th',[_c('div',{staticClass:"boxBody"},[_c('filter-label-component',{attrs:{"searchParam":_vm.searchParam,"title":'Balance',"fieldName":'balance',"filtersHidden":_vm.filtersHidden},on:{"changeFilter":_vm.changeFilter}}),(_vm.searchParam.balance)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'balance')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.balance,
                    textBlue: _vm.searchParam.balance,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.balance),callback:function ($$v) {_vm.$set(_vm.searchParam, "balance", $$v)},expression:"searchParam.balance"}}):_vm._e()],1)]),_c('vs-th',[_c('div',{staticClass:"boxBody"},[_c('filter-label-component',{attrs:{"searchParam":_vm.searchParam,"title":'Days in arrears',"fieldName":'daysInArrears',"filtersHidden":_vm.filtersHidden},on:{"changeFilter":_vm.changeFilter}}),(_vm.searchParam.daysInArrears)?_c('span',{staticClass:"iconClose",on:{"click":function($event){return _vm.resetInput($event, 'daysInArrears')}}},[_vm._v("X")]):_vm._e(),(!_vm.filtersHidden)?_c('vs-input',{staticClass:"w-auto",class:{
                    isFocus: _vm.searchParam.daysInArrears,
                    textBlue: _vm.searchParam.daysInArrears,
                  },on:{"change":function($event){return _vm.onChangeInput($event)}},model:{value:(_vm.searchParam.daysInArrears),callback:function ($$v) {_vm.$set(_vm.searchParam, "daysInArrears", $$v)},expression:"searchParam.daysInArrears"}}):_vm._e()],1)]),_c('vs-th',{staticClass:"filter-cross",class:{ toggle: !_vm.filtersHidden }},[_c('filter-icon',{attrs:{"size":"1.5x","fill":_vm.filterIconColor.fill,"stroke":_vm.filterIconColor.stroke},on:{"click":_vm.toggleFilter}})],1)],1)],2),_c('div',[_c('vs-row',{staticClass:"vs-row justify-between my-5 items-center"},[_c('div',{staticClass:"records-per-page flex items-center"},[_c('label',{staticClass:"w-full mb-0"},[_vm._v("Records per page")]),_c('vs-select',{staticClass:"per-pg",model:{value:(_vm.searchParam.limit),callback:function ($$v) {_vm.$set(_vm.searchParam, "limit", $$v)},expression:"searchParam.limit"}},_vm._l((_vm.limitValue),function(item,index){return _c('vs-select-item',{key:index,attrs:{"text":item.text,"value":item.value}})}),1)],1),_c('div',[_c('vs-row',[_c('vPegination',{attrs:{"page-count":_vm.totalPage,"total":_vm.totalPage},model:{value:(_vm.searchParam.pageNumber),callback:function ($$v) {_vm.$set(_vm.searchParam, "pageNumber", $$v)},expression:"searchParam.pageNumber"}})],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }